import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import Banner from '../../components/Banner';
import { getYoutubeId } from '../../utils/functions';
import { AcademyProps } from './models';

import './Academy.scss';

const Academy: FC<{ data: AcademyProps }> = ({
  data: {
    academy: {
      banner,
      logo,
      qrCodeDescription,
      qrCodeImage,
      qrCodeSecondaryImage,
      seo,
      socialItems,
      urls,
      youtubeVideos,
      videosTitle,
    },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;
  const [showModal, setShowModal] = React.useState(false);
  const [videoId, setVideoId] = React.useState('');

  const showYoutubeVideo = (videoUrl: string) => {
    if (!videoUrl) return;
    setVideoId(getYoutubeId(videoUrl));
    setShowModal(true);
  };

  const hideModal = () => setShowModal(false);

  const ALACTA_ACADEMY = 'alacta-academy';
  const ACADEMY_ACCESS = 'academy-access';
  const ACADEMY_VIDEOS = 'academy-videos';
  const SOCIAL_MEDIA = 'social-media';

  return (
    <Layout headerTransparent alterLogo={logo || null}>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className={classnames(ALACTA_ACADEMY, 'px-4')}>
        {banner ? <Banner data={banner} /> : null}

        <section
          className={classnames(
            ACADEMY_ACCESS,
            'd-flex flex-column flex-md-row align-items-center justify-content-md-between align-items-md-center text-center'
          )}
        >
          <div className={classnames(`${ACADEMY_ACCESS}__codeQr`, 'col-12 col-md-4')}>
            {qrCodeImage ? (
              <Image imageData={qrCodeImage} alt={qrCodeImage.altText} className="image" />
            ) : null}
          </div>

          <div
            className={classnames(
              `${ACADEMY_ACCESS}__app`,
              'col-12 my-5  mt-md-0 col-md-8 col-lg-7 col-xl-7'
            )}
          >
            <h2 className="title text-cherry">
              {qrCodeDescription ? <DangerouslySetInnerHtml html={qrCodeDescription} /> : null}
            </h2>
            {qrCodeSecondaryImage ? (
              <Image
                imageData={qrCodeSecondaryImage}
                alt={qrCodeSecondaryImage.altText}
                className="image"
              />
            ) : null}
          </div>
        </section>

        <section className={classnames(ACADEMY_VIDEOS)}>
          {videosTitle ? (
            <h2
              className={classnames(
                `${ACADEMY_VIDEOS}__title`,
                `${ACADEMY_VIDEOS}__title--main`,
                'text-center'
              )}
            >
              {videosTitle}
            </h2>
          ) : null}
          <div
            className={classnames(
              `${ACADEMY_VIDEOS}__gridVideos`,
              'd-flex flex-column flex-md-row justify-content-between align-items-center mt-5'
            )}
          >
            {youtubeVideos?.map(({ image, title, youtubeLink, ariaLabel }) => (
              <div className="video col-8 text-center" key={`video_${title}`}>
                <h2 className="video__title text-center mb-md-3">{title}</h2>
                <button
                  type="button"
                  onClick={() => showYoutubeVideo(youtubeLink)}
                  className="video__preview overflow-hidden border-0 px-0"
                  aria-label={ariaLabel}
                >
                  <Image imageData={image} alt={image.altText} className="image" />
                </button>
              </div>
            ))}
          </div>
        </section>

        <section
          className={classnames(
            SOCIAL_MEDIA,
            'd-flex flex-column justify-content-md-around justify-content-lg-between align-items-lg-center flex-md-row mt-5'
          )}
        >
          {socialItems
            ? socialItems.map(({ iconImage, link }) => (
                <Link
                  to={link.url}
                  target="_blank"
                  className={classnames(
                    `${SOCIAL_MEDIA}__item`,
                    'col-8 d-flex justify-content-evenly align-items-center text-decoration-none justify-content-md-start'
                  )}
                  rel="noreferrer"
                >
                  <Image
                    imageData={iconImage}
                    alt={iconImage.altText}
                    className={classnames(`${SOCIAL_MEDIA}__item--img`)}
                  />
                  <span>{link.name}</span>
                </Link>
              ))
            : null}
        </section>

        <Modal show={showModal} onHide={hideModal} size="lg">
          <Modal.Header closeButton />
          <Modal.Body className="w-100 p-0">
            {videoId ? <YouTubeLazyLoaded videoId={videoId} autoplay /> : null}
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    academy(url: { eq: $url }) {
      logo {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 480, quality: 100)
        }
      }
      banner {
        topText
        title
        subTitle
        body
        link {
          name
          udi
          url
          icon
          queryString
        }
        cssExtraClass
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 960, quality: 100)
          }
        }
        mobileImage {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 960, quality: 100)
          }
        }
      }
      qrCodeImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 480, quality: 100)
        }
      }
      qrCodeDescription
      qrCodeSecondaryImage {
        altText
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 480, quality: 100)
        }
      }
      videosTitle
      youtubeVideos {
        ariaLabel
        title
        youtubeLink
        image {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 480, quality: 100)
          }
        }
      }
      socialItems {
        ariaLabel
        icon
        iconImage {
          altText
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 480, quality: 100)
          }
        }
        link {
          name
          udi
          url
          icon
          queryString
        }
        cssExtraClass
      }
      seo {
        ...SEOStructureFragment
      }
      urls {
        lang
        href
      }
    }
  }
`;

export default Academy;
